import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        className="container-fluid  text-dark-50 footer wow fadeIn"
        data-wow-delay="0.1s"
        style={{ backgroundColor: "#d8e6fd" }}
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6 get-in-touch">
              <h5 className="text-dark mb-4">Contact Us</h5>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i> Plot 251/252
                Herbert Macaulay way Millennium Plaza Central Business District
                Opposite NNPC Towers.
              </p>
              <p className="mb-2">
                <a href="tel:+2349152415488">
                  <i className="fa fa-phone-alt me-3"></i>+234 915 241 5488
                </a>
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>
                info@royalminesproperty.com
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-outline-light btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="http://facebook.com/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <h5 className="text-dark mb-4">Quick Links</h5>
              <Link className="btn btn-link text-dark-50" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link text-dark-50" to="/contact">
                Contact Us
              </Link>
              <Link className="btn btn-link text-dark-50" to="/properties">
                {" "}
                Our Properties
              </Link>
              <Link
                className="btn btn-link text-dark-50"
                to="/frequently-asked-questions"
              >
                {" "}
                frequently asked questions
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; {year}- <Link to="/">RoyalMines Property</Link>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="/frequently-asked-questions">FQAs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}

export default Footer;
